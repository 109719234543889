a .span1{
    transform: translateX(-120%);
}
a:hover .span1 {
    transform:  translateX(0);

}
a:hover .span2{
    transform:  translateX(0);

}


a:not(:hover) .span1 {
    opacity: 0;

}
a:not(:hover) .span2 {
    transform: translateX(130%);
    opacity: 1;
    
}


a .span2{
    opacity: 0;
}


.cross-container {
    position: relative;
   
  }
  
  .line {
    position: absolute;
    width: 100%;
    height: 2px;
   
  }
  
  .line1 {
    transform: rotate(45deg);
  }
  
  .line2 {
    transform: rotate(-45deg);
  }
  

  .up-down-box:hover {
    transform: translateY(-50%);
  }

  .option-box:hover span{
    transform: translateX(0);
  }

  #nav-bar{
    background: linear-gradient(to right, rgba(27, 22, 60, 1)
    , rgba(105, 10, 176, 0.8),rgba(30, 23, 68, 1));
    
   
  }

  #nav-bar .active{
    color: rgb(255, 255, 255);
   font-weight: 1000;
   font-size: x-large;
  }