.intro-point::before{
    content: '\2713'; /* Unicode for check mark */
    /* font-size: 20px; */
    color: #85CEBD;
    padding-right: 4px;
}
.more-about-us{
    box-shadow: rgba(99, 93, 130, 0.7)0px 2px 12px, rgba(35, 30, 64, 0.7) 0px 10px 10px;
    background-color: rgb(16, 16, 16);
    color: white;
 outline: 2px solid #560C8E;
    /* border-radius:20px ; */
}
.more-about-us:hover{
    background-color: #560c8e;

}

.experiance-box{
    box-shadow:rgba(99, 93, 130, 0.7)2px 3px 12px, rgba(35, 30, 64, 0.7) 1px 10px 10px;;
}