/* .landing-content-box{
    background: linear-gradient(to right, rgba(27, 22, 60, 0.7)
    , #690ab0b3,rgba(30, 23, 68, 0.7));
 
} */

/* 
.background-shade-box::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(27, 22, 60, 0.7), white);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
    /* z-index: -1; */
/* } */

.background-triangle {
  clip-path: polygon(50% 110%, 20% 0, 80% 0);
  z-index: -1;
  background-color: #7d3cd2;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
  filter: blur(800px);
  opacity: 0.8;
}

.background-shadow-box {
  background: linear-gradient(
    to bottom,
    rgba(27, 22, 60, 0.7),
    rgba(105, 10, 176, 0.3),
    rgba(30, 23, 68, 0.6)
  );
  filter: blur(10px);
  z-index: -10;
}

.discover-btn {
  box-shadow: rgba(99, 93, 130, 0.7) 0px 2px 20px,
    rgba(35, 30, 64, 0.7) 0px 10px 10px;
  background-color: #560c8e;

  outline: 3px solid #85cebd;

  color: white;
  /* font-weight: 500; */
  /* border-radius:20px ; */
}
.discover-btn:hover {
  background-color: #85cebd;
  outline: 2px solid #85cebd;
  color: black;
}
.hire-btn {
  box-shadow: rgba(99, 93, 130, 0.7) 0px 2px 12px,
    rgba(35, 30, 64, 0.7) 0px 10px 10px;
  background-color: rgb(16, 16, 16);
  color: white;
  outline: 2px solid #560c8e;
  /* border-radius:20px ; */

  /* border-radius:20px ; */
}
.hire-btn:hover {
  background-color: #560c8e;
  color: white;
  outline: 2px solid #85cebd;

}

@keyframes moveDown {
  0% {
    transform: translateY(-40%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.arrow-icon {
  animation: moveDown 1.5s ease-in-out infinite;
}
